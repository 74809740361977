import { BaseModel } from "../../../../service/models/base";
import { ApprovalModel } from "../../model/purchase_order_model";

export interface ProcurementProjectModel extends BaseModel {
  name: string;
  description: string;
  amount: number;
  spent: number;
  reason?: string;
  br_id?: string;
  approval_required: boolean;
  approval_status: string;
  approved_at?: Date;
  approved_by?: string;
  approved_by_name?: string;
  approval_level?: string;
  approvals?: ApprovalModel[];
  current_approval_level?: string;
  current_approval_required_count?: number;
  current_approval_count?: number;
  current_reject_count?: number;
}

export interface Project extends BaseModel {
  name: string;
  description: string;
  category: string;
  batch_name: string;
  batch_size: number;
  entry: ProjectEntry[];
}

export const project_approval_status = {
  pending: "Pending",
  approved: "Approved",
  rejected: "Rejected",
};

export interface ProjectEntry {
  amount: string;
  status: string;
  type: "Dr" | "Cr";
  budget_id?: string;
}

export const procurementProjectDefaultValue: ProcurementProjectModel = {
  name: "",
  description: "",
  amount: 0,
  spent: 0,
  approval_required: true,
  approval_status: project_approval_status.pending,
};

export const projectDefaultValue: Project = {
  name: "",
  description: "",
  batch_name: "",
  batch_size: 0,
  category: "",
  entry: []
};



