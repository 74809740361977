import { FunctionComponent, useEffect, useState } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
// import DrawerWidget from "../../../../context_providers/modal/drawer_widget";
import LoadingWidget from "../../../context_providers/modal/loader";
import {
  useToast,
  ToastType,
} from "../../../context_providers/toast/toast_ctx";
import { fetchPut, fetchPost, fetchGet } from "../../../service/api_client";
import DoneWidget from "../../../ui/new_components/common/done";
import { currencyFormat } from "../../../utils/orders_utils";
import { urls } from "../../../utils/urls";
import {
  Project,
  projectDefaultValue,
} from "../../purchase_order/projects/model/project_model";

interface ProjectCreateScreenProps {
  data?: Project;
  onClose: (id: Project) => void;
}

const ProjectCreate: FunctionComponent<ProjectCreateScreenProps> = (props) => {
  // const { user } = useAuth();
  const { id } = useParams<{ id: string }>();
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/project_master`;
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSucess] = useState(false);
  const { showToast } = useToast();
  // const [addNewTaxId, setAddNewTaxId] = useState<number>();
  // const [oldTotal, setOldTotal] = useState(0);
  const [data, setData] = useState<Project>({
    ...projectDefaultValue,
  });

  const is_update_page = useRouteMatch(urls.project_master + "/update/:id");
  // const [addNewId, setAddNewId] = useState(Math.random() * 10000);

  // const [disabled, setDisabled] = useState(false);

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!data.name) {
      return showToast({
        type: ToastType.error,
        text: "Please fill all the required details",
      });
    }

    setLoading(true);
    const res = data.id
      ? await fetchPut(url + "/" + data.id, data)
      : await fetchPost(url, data);
    if (res.success) {
      if (!data.id) setShowSucess(true);
      setData(res.data);
      showToast({ type: ToastType.success, text: res.message });
      if (props.onClose) props.onClose(res.data);
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  useEffect(() => {
    if (id && is_update_page) getData(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async (id: string) => {
    setLoading(true);
    const res = await fetchGet(url + "/" + id);
    if (res.success) {
      setData({ ...res.data });
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  // const onSelectAttchment = (d: AttachmentModel) => {
  //   window.open(d.url, "_blank");
  // };
  if (showSuccess)
    return (
      <div className="w-full h-full flex flex-col items-center justify-center">
        <div className="w-80 h-80 p-0">
          <DoneWidget />
        </div>
        <div className="flex flex-col gap-2 justify-start items-center">
          <h1 className="text-3xl font-semibold text-gray-400 ">
            Created successfully
          </h1>
          <span className="text-black italic font-semibold"># {data.id}</span>
          <div className="flex gap-5">
            <button
              onClick={() => setShowSucess(false)}
              className="border rounded-3xl px-5 py-2 text-green-800 border-green-400"
            >
              Continue
            </button>
            <button
              onClick={() => {
                setData({
                  ...projectDefaultValue,
                });
                setShowSucess(false);
              }}
              className="border rounded-3xl px-4 py-2 bg-green-400 text-white  "
            >
              Create new
            </button>
          </div>
        </div>
      </div>
    );

  return (
    <>
      <form onSubmit={submit} className=" py-2 px-6">
        <div className="flex items-center justify-between mb-2">
          <div className="text-xl font-bold border-l-4 border-myPrimaryColor pl-2">
            Project <span className="text-sm">#{data.id}</span>
          </div>
        </div>

        <div className="bg-white rounded-lg px-3 py-5 ">
          <div className="grid grid-cols-1 md:grid-cols-2 text-sm">
            <div className="flex flex-col gap-5 w-full">
              <div className="grid grid-cols-4">
                <label htmlFor="name">Name</label>
                <input
                  value={data.name}
                  onChange={(e) => {
                    setData((o) => ({ ...o, name: e.target.value }));
                  }}
                  type="text"
                  id="name"
                  required
                  placeholder="Enter project name "
                  className=" rounded bg-gray-100 p-1 col-span-3 focus:outline-none"
                />
              </div>
              <div className="grid grid-cols-4">
                <label htmlFor="description">Description</label>
                <textarea
                  value={data.description}
                  onChange={(e) => {
                    setData((o) => ({ ...o, description: e.target.value }));
                  }}
                  rows={5}
                  id="description"
                  placeholder="Enter project description"
                  className=" rounded bg-gray-100 p-1 col-span-3 focus:outline-none"
                />
              </div>
              <div className="grid grid-cols-4">
                <label htmlFor="category">category</label>
                <select
                  value={data.category}
                  onChange={(e) => {
                    setData((o) => ({ ...o, category: e.target.value }));
                  }}
                  id="category"
                  required
                  className=" rounded bg-gray-100 p-1 col-span-3 focus:outline-none"
                >
                  <option value="" hidden>
                    Select
                  </option>
                  {["R&D", "Hub", "Tools", "Production"].map((cat) => (
                    <option id={cat} value={cat}>
                      {cat}
                    </option>
                  ))}
                </select>
              </div>
              <div className="grid grid-cols-4">
                <label htmlFor="batch_name">Batch name</label>
                <input
                  value={data.batch_name}
                  onChange={(e) => {
                    setData((o) => ({ ...o, batch_name: e.target.value }));
                  }}
                  type="text"
                  id="batch_name"
                  required
                  placeholder="Enter project Batch name "
                  className=" rounded bg-gray-100 p-1 col-span-3 focus:outline-none"
                />
              </div>
              <div className="grid grid-cols-4">
                <label htmlFor="batch_size">Batch size</label>
                <input
                  value={data.batch_size}
                  onChange={(e) => {
                    setData((o) => ({
                      ...o,
                      batch_size: Number(e.target.value || 0),
                    }));
                  }}
                  type="number"
                  id="batch_size"
                  required
                  placeholder=""
                  className=" rounded bg-gray-100 p-1 col-span-3 focus:outline-none text-right"
                />
              </div>
              {/* <div className="grid grid-cols-4">
                <label htmlFor="type">Type</label>
                <select id="type" className="col-span-3 border bg-gray-100 p-1 focus:outline-none text-right">
                  <option value="debit">Debit</option>
                  <option value="credit">Credit</option>
                </select>
              </div> */}

              {/* <div className="grid grid-cols-4">
                <label htmlFor="spent">Spent</label>
                <input
                  value={data.spent}
                  onChange={(e) => {
                    setData((o) => ({
                      ...o,
                      spent: Number(e.target.value || 0),
                    }));
                  }}
                  type="number"
                  id="spent"
                  placeholder=""
                  className=" rounded bg-gray-100 p-1 col-span-3 focus:outline-none text-right"
                />
              </div>
              <div className="grid grid-cols-4">
                <label htmlFor="spent">Balance</label>
                <div className=" rounded bg-gray-100 p-1 col-span-3 focus:outline-none text-right">
                  {currencyFormat(data.amount - data.spent)}
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div className="flex justify-center gap-5 text-sm my-5">
          <button className="rounded-lg p-2 px-5 bg-teal text-white  hover:bg-green-800 border border-teal hover:border-gree">
            Submit
          </button>
          <button
            onClick={() => window.history.back()}
            type="button"
            className="rounded-lg p-2 px-5 border border-teal hover:bg-teal hover:text-white"
          >
            Cancel
          </button>
        </div>
      </form>
      {loading && <LoadingWidget />}
    </>
  );
};

export default ProjectCreate;
