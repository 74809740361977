import { RouteParam } from "../../../routes";
import { MODULE, ACTION } from "../../../utils/enums";
import { urls } from "../../../utils/urls";
import PaymentRequestApprovalList from "../payment_request/payment_request_approval_list";
import PaymentRequestList from "../payment_request/payment_request_list";
import ProjectCreateScreen from "../projects/project_budgets/project_budget_create/project_create_screen";

const payment_request_routes: RouteParam[] = [
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.po_payment_request,
    component: PaymentRequestList,
    module: MODULE.purchase_order,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.po_payment_request_approval,
    component: PaymentRequestApprovalList,
    module: MODULE.purchase_order,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.po_payment_request + "/create",
    component: ProjectCreateScreen,
    module: MODULE.purchase_order,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
  {
    isPrivate: true,
    fullScreen: false,
    exact: true,
    path: urls.po_payment_request + "/update/:id",
    component: ProjectCreateScreen,
    module: MODULE.purchase_order,
    main_module: MODULE.procurement_panel,
    action: [ACTION.CREATE, ACTION.READ, ACTION.UPDATE],
    operator: "or",
  },
];

export default payment_request_routes;
