import { FunctionComponent, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import LoadingWidget from "../../../../../context_providers/modal/loader";
import {
  useToast,
  ToastType,
} from "../../../../../context_providers/toast/toast_ctx";
import {
  fetchPut,
  fetchPost,
  fetchGet,
} from "../../../../../service/api_client";
import DoneWidget from "../../../../../ui/new_components/common/done";
import { currencyFormat } from "../../../../../utils/orders_utils";
import {
  ProcurementProjectModel,
  procurementProjectDefaultValue,
  project_approval_status,
} from "../../model/project_model";
import { urls } from "../../../../../utils/urls";
import ModalDialog from "../../../../../ui/new_components/common/modal";
import ApprovalBottomSheetWidget from "./components/bottom_sheet";

interface ProjectApprovalScreenProps {}

const ProjectApprovalScreen: FunctionComponent<ProjectApprovalScreenProps> = (
  props
) => {
  const { id } = useParams<{ id: string }>();
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/project`;
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [showRejectionModal, setShowRejectionModal] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const { showToast } = useToast();
  const [data, setData] = useState<ProcurementProjectModel>({
    ...procurementProjectDefaultValue,
  });
  const navaigation = useHistory();

  const submit = async ({
    approval_status,
    level,
  }: {
    approval_status: string;
    level: string;
  }) => {
    let reason = "";
    if (
      approval_status === project_approval_status.rejected ||
      approval_status === project_approval_status.pending
    ) {
      reason =
        window.prompt(
          approval_status === project_approval_status.rejected
            ? "What's the reason for Rejection ?"
            : "What's the reason for Pending ?"
        ) || "";
      reason = reason.trim();
      if (!reason) return;
    }
    if (
      !window.confirm(
        `Are you sure to ${approval_status} the ${level} approval ?`
      )
    )
      return;

    setLoading(true);
    console.log("Level:", level)
    const res = await fetchPut(url + "/approve/" + data.id, {
      approval_status,
      level,
      id: data.id,
      reason,
    });

    if (res.success) {
      setData((o) => ({ ...o, ...res.data }));
      showToast({ type: ToastType.success, text: res.message });
      if(id) getData(id);
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  const handleApprove = async () => {
    setShowApprovalModal(false);
    setLoading(true);
    const res = await fetchPut(url + "/approve/" + data.id, {
      ...data,
      // status: "Approved",
    });
    if (res.success) {
      showToast({ type: ToastType.success, text: res.message });
      navaigation.push(urls.procurement_project_approval);
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  const handleReject = async () => {
    setShowApprovalModal(false);
    setLoading(true);
    const res = await fetchPut(url + "/approve/" + data.id, {
      ...data,
      status: "Rejected",
      reason: rejectionReason,
    });
    if (res.success) {
      showToast({ type: ToastType.success, text: res.message });
      navaigation.push(urls.procurement_project_approval);
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  useEffect(() => {
    if (id) getData(id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getData = async (id: string) => {
    setLoading(true);
    const res = await fetchGet(url + "/" + id);
    if (res.success) {
      setData({ ...res.data });
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  return (
    <>
      <div className="py-2 px-6">
        <div className="flex items-center justify-between mb-2">
          <div className="text-xl font-bold border-l-4 border-myPrimaryColor pl-2">
            Project <span className="text-sm">#{data.id}</span>
          </div>
        </div>

        <div className="bg-white rounded-lg px-3 py-5">
          <div className="grid grid-cols-1 md:grid-cols-2 text-sm">
            <div className="flex flex-col gap-5 w-full">
              <div className="grid grid-cols-4">
                <label htmlFor="name">Name</label>
                <input
                  value={data.name}
                  onChange={(e) =>
                    setData((o) => ({ ...o, name: e.target.value }))
                  }
                  disabled={true}
                  type="text"
                  id="name"
                  required
                  placeholder="Enter project name"
                  className="rounded bg-gray-100 p-1 col-span-3 focus:outline-none"
                />
              </div>
              <div className="grid grid-cols-4">
                <label htmlFor="status">Status</label>
                <input
                  value={data.approval_status}
                  onChange={(e) =>
                    setData((o) => ({
                      ...o,
                      status: e.target.value,
                    }))
                  }
                  disabled={true}
                  type="string"
                  id="status"
                  required
                  className="rounded bg-gray-100 p-1 col-span-3 focus:outline-none text-right"
                />
              </div>
              <div className="grid grid-cols-4">
                <label htmlFor="amount">Amount</label>
                <input
                  value={data.amount}
                  onChange={(e) =>
                    setData((o) => ({
                      ...o,
                      amount: Number(e.target.value || 0),
                    }))
                  }
                  disabled={true}
                  type="number"
                  id="amount"
                  required
                  className="rounded bg-gray-100 p-1 col-span-3 focus:outline-none text-right"
                />
              </div>
            </div>
          </div>
        </div>

        {/* <div className="flex justify-center gap-5 text-sm my-5">
          <button
            type="button"
            onClick={() => setShowApprovalModal(true)}
            className="rounded-lg p-2 px-5 bg-teal text-white hover:bg-green-800 border border-teal hover:border-green-600"
          >
            Approve
          </button>
          <button
            onClick={() => setShowRejectionModal(true)}
            type="button"
            className="rounded-lg p-2 px-5 border border-teal hover:bg-teal hover:text-white"
          >
            Reject
          </button>
        </div> */}
      </div>

      {loading && <LoadingWidget />}

      {showApprovalModal && (
        <ModalDialog
          title="Confirm Approval"
          onClose={() => setShowApprovalModal(false)}
          show={true}
        >
          <p>Are you sure you want to approve this project?</p>
          <button
            className="border rounded-3xl px-5 py-2 text-green-800 border-green-400"
            onClick={handleApprove}
          >
            Yes
          </button>
          <button
            className="border rounded-3xl px-5 py-2 text-red-800 border-red-400"
            onClick={() => setShowApprovalModal(false)}
          >
            No
          </button>
        </ModalDialog>
      )}

      {showRejectionModal && (
        <ModalDialog
          title="Enter Rejection Reason"
          onClose={() => setShowRejectionModal(false)}
          show={true}
        >
          <textarea
            value={rejectionReason}
            onChange={(e) => setRejectionReason(e.target.value)}
            placeholder="Enter reason for rejection"
            className="rounded bg-gray-100 p-1 w-full focus:outline-none"
          />
          <button
            className="border rounded-3xl px-5 py-2 text-green-800 border-green-400"
            onClick={handleReject}
          >
            Submit
          </button>
          <button
            className="border rounded-3xl px-5 py-2 text-red-800 border-red-400"
            onClick={() => setShowRejectionModal(false)}
          >
            Cancel
          </button>
        </ModalDialog>
      )}
      <div className="sticky bottom-0 right-0 mt-3 w-full rounded-t-md bg-white shadow-sm py-3 px-5 border">
        {" "}
        <h3 className="text-sm font-semibold">Approval status</h3>
        <ApprovalBottomSheetWidget data={data} onSubmit={submit} />
      </div>
    </>
  );
};

export default ProjectApprovalScreen;
