import { FunctionComponent } from "react";
import {
  mdiCheckboxMarkedCircleOutline,
  mdiCloseCircleOutline,
} from "@mdi/js";
import Icon from "@mdi/react";
import moment from "moment";
import { ApprovalModel } from "../../../../model/purchase_order_model";
import ApprovalProgressBar from "../../../../purchase_order_create/components/approval_progress_bar";
import { project_approval_status } from "../../../model/project_model";


interface ApprovalDataProps {
  approvals: ApprovalModel[];
  approved_count: number;
  approval_count_required: number;
  approval_level: string;
}

const ApprovalData: FunctionComponent<ApprovalDataProps> = (props) => {
  // const [expanded, setExpanded] = useState(true);

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="">
          <div className="flex justify-between">
            <p className="text-sm">{props.approval_level}</p>
            <p className="text-sm">{`  ${props.approved_count}/${props.approval_count_required}`}</p>
          </div>
          <ApprovalProgressBar
            approval_count_required={props.approval_count_required}
            approved_count={props.approved_count}
            approval_level={props.approval_level}
          />
        </div>

        <div className="">
          <div className="flex justify-between text-sm">
            <p className="italic">Approved by</p>{" "}
          </div>

          {/* {expanded && ( */}
          <div className="flex flex-col gap-1">
            {props.approvals.map((app) => (
              <div className="rounded border p-2  text-sm bg-white">
                <div className="flex justify-between items-center">
                  <div className="flex gap-1">
                    {app.approval_status === project_approval_status.approved ? (
                      <Icon
                        path={mdiCheckboxMarkedCircleOutline}
                        size={0.7}
                        className="text-green-400"
                      />
                    ) : (
                      <Icon
                        path={mdiCloseCircleOutline}
                        size={0.7}
                        className="text-red-400"
                      />
                    )}
                    <p>{app.approved_by_name}</p>
                  </div>
                  <div className="font-semibold">{app.approval_status}</div>
                </div>
                <div className="flex justify-between">
                  <div className="">
                    {app.reason && (
                      <p className="text-xs italic">
                        <b>Reason : </b>
                        {app.reason}
                      </p>
                    )}
                  </div>
                  <p className="text-xs italic">
                    {moment(app.approved_at).format("lll")}
                  </p>
                </div>
              </div>
            ))}
          </div>
          {/* )} */}
        </div>
      </div>
    </>
  );
};

export default ApprovalData;
