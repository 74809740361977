import {
  mdiCheck,
  mdiCheckboxMarkedCircleOutline,
  mdiChevronDown,
  mdiChevronUp,
  mdiClose,
  mdiCloseCircleOutline,
  mdiInformationOutline,
} from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useState } from "react";
import ProgressBar from "../../../inventory/items/components/progress_bar";
import {
  PurchaseOrderModel,
  approval_level,
  approval_threshold,
  getPoApprovedCounts,
  levelModule,
  po_approval_status,
} from "../../model/purchase_order_model";
import ApprovalProgressBar from "./approval_progress_bar";
import { Link, useHistory } from "react-router-dom";
import { urls } from "../../../../utils/urls";
import moment from "moment";
import StepperWidget from "../../../../ui/new_components/common/stepper_widget";
import { useAuth } from "../../../../context_providers/auth/authProvider";
import {
  useModal,
  ModalType,
  ModalAlignment,
} from "../../../../context_providers/modal/modal_context";
import { ACTION, PO_STATUS } from "../../../../utils/enums";
import ApprovalData from "../../purchase_order_approval/components/approval_data_sheet";
import {
  ToastType,
  useToast,
} from "../../../../context_providers/toast/toast_ctx";
import ModalDialog from "../../../../ui/new_components/common/modal";

interface PoCreateBottomSheetWidgetProps {
  data: PurchaseOrderModel;
  onSubmit: () => void;
}

const PoCreateBottomSheetWidget: FunctionComponent<
  PoCreateBottomSheetWidgetProps
> = (props) => {
  const { user, isAuthorised } = useAuth();
  const { showModal } = useModal();
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [showActionBtn, setshowActionBtn] = useState(false);
  const navaigation = useHistory();
  const [showItemsModal, setShowItemsModal] = useState(false);
  const { showToast } = useToast();
  const [forecloseMessage, setForecloseMessage] = useState(
    props.data.foreclose_message || ""
  );

  const [approvalCounts, setapprovalCounts] = useState<{
    [key: string]: number;
  }>({});
  const [approvalLevels, setapprovalLevels] = useState<{
    [key: string]: number;
  }>({});

  const changePoStatus = () => {
    props.data.status = PO_STATUS.closed;
    showToast({ type: ToastType.success, text: "PO closed" });
  };

  const handleItemsModal = async () => {
    setShowItemsModal(false);
    props.data.foreclose_message = forecloseMessage;
    props.onSubmit(); // Pass forecloseMessage to onSubmit
    setLoading(true);
    showToast({ type: ToastType.success, text: "Foreclosed PO" });
    navaigation.push(urls.purchase_order);
    setLoading(false);
  };

  useEffect(() => {
    // Update forecloseMessage whenever props.data.foreclose_message changes
    setForecloseMessage(props.data.foreclose_message || "");
  }, [props.data.foreclose_message]);

  useEffect(() => {
    const counts = getPoApprovedCounts({ approvals: props.data.approvals });
    setapprovalCounts(counts);

    const rule =
      approval_threshold[props.data.approval_level || approval_level.level_0];

    const t = rule.approvals_required.reduce(
      (
        pv: {
          [key: string]: number;
        },
        v: { level: string; count: number }
      ) => ({ ...pv, [v.level]: v.count }),
      {}
    );

    setapprovalLevels(t);
  }, [props.data]);

  useEffect(() => {
    setshowActionBtn(
      (props.data.current_approval_required_count ?? 0) >
        (props.data.current_approval_count ?? 0) &&
        isAuthorised({
          action: ACTION.READ,
          module: levelModule[props.data.current_approval_level!],
        })
    );
  }, [props.data]);
  const levels = Object.keys(approvalLevels);

  return (
    <div className="">
      {props.data.approval_level !== approval_level.level_0 && (
        <div className="mt-2 border rounded-md p-2 bg-gray-50 mb-2">
          <div className="pb-5">
            <StepperWidget
              steps={levels.map((level, i) => ({
                label: level,
                validated:
                  i < levels.indexOf(props.data.current_approval_level!) ||
                  (props.data.approval_level ==
                    props.data.current_approval_level &&
                    (props.data.current_approval_count ?? 0) >=
                      (props.data.current_approval_required_count ?? 0)),
                required: false,
              }))}
              activeStep={levels.indexOf(props.data.current_approval_level!)}
              onChange={function (step: number): void {
                showModal({
                  container: (i) => (
                    <div className="p-5">
                      <ApprovalData
                        approvals={
                          props.data.approvals?.filter(
                            (f) => f.level == levels[step]
                          ) ?? []
                        }
                        approved_count={approvalCounts[levels[step]] ?? 0}
                        approval_count_required={approvalLevels[levels[step]]}
                        approval_level={levels[step]}
                      />
                    </div>
                  ),
                  title: `${levels[step]} Approval Detail`,
                  type: ModalType.drawer,
                  alignment: ModalAlignment.right,
                });
              }}
            />
          </div>
        </div>
      )}
      <div className="flex justify-between ">
        <div className="">
          <div className="flex gap-1 text-gray-500 text-xs mt-2 items-center">
            <Icon path={mdiInformationOutline} size={0.8}></Icon>
            This Purchase order requires {props.data.approval_level} approval
          </div>
        </div>

        <div className="text-sm font-semibold flex gap-2">
          {props.data.current_approval_level === props.data.approval_level &&
            props.data.current_approval_count ===
              props.data.current_approval_required_count &&
            props.data.po_no && (
              <button
                onClick={() => setShowItemsModal(true)}
                className={`text-center px-3 py-1 rounded-md bg-myPrimaryColor text-white text-sm ${
                  props.data.items.reduce((acc, next) => {
                    acc +=
                      next.unit_no -
                      (next.received_unit_no - next.returned_unit_no);
                    console.log("acc", acc);
                    return acc;
                  }, 0) === 0
                    ? "hidden"
                    : ""
                }`}
              >
                Foreclose PO
              </button>
            )}
          {/* <button
            onClick={() => setShowItemsModal(true)}
            className={`${
              false ? "bg-gray-400 cursor-not-allowed" : "bg-myPrimaryColor"
            } rounded-md px-3 py-1 text-white`}
          >
            Foreclose PO
          </button> */}

          {showItemsModal && (
            <ModalDialog
              title="Items ForeClose Modal"
              onClose={() => setShowItemsModal(false)}
              show={true}
            >
              <p>Are you sure you want to foreclose this PO?</p>

              {/* Table for displaying item details */}
              <table className="min-w-full mt-4 border-collapse">
                <thead>
                  <tr>
                    <th className="border px-4 py-2">Item Name</th>
                    <th className="border px-4 py-2">Unit No</th>
                    <th className="border px-4 py-2">Received Unit No</th>
                  </tr>
                </thead>
                <tbody>
                  {props.data.items &&
                    props.data.items.map((item, index) => (
                      <tr key={index}>
                        <td className="border px-4 py-2">
                          {item.item?.name || "N/A"}
                        </td>
                        <td className="border px-4 py-2">{item.unit_no}</td>
                        <td className="border px-4 py-2">
                          {item.received_unit_no}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {/* Input for the foreclose message */}
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700">
                  Foreclose Message
                </label>
                <input
                  type="text"
                  value={forecloseMessage} // Controlled input bound to the state
                  onChange={(e) => setForecloseMessage(e.target.value)} // Update the state when input changes
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md"
                  placeholder="Enter message"
                />
              </div>

              <button
                className="border rounded-3xl px-5 py-2 text-green-800 border-green-400 mt-4"
                onClick={handleItemsModal}
              >
                Yes
              </button>
            </ModalDialog>
          )}

          {props.data.current_approval_level === props.data.approval_level &&
            props.data.current_approval_count ===
              props.data.current_approval_required_count &&
            props.data.po_no && (
              <Link
                to={urls.purchase_voucher + `/create?po_no=${props.data.po_no}`}
                className={`text-center px-3 py-1 rounded-md bg-myPrimaryColor text-white text-sm ${
                  props.data.items.reduce((acc, next) => {
                    acc +=
                      next.unit_no -
                      (next.received_unit_no - next.returned_unit_no);
                    return acc;
                  }, 0) === 0
                    ? "hidden"
                    : ""
                }`}
              >
                Inward
              </Link>
            )}

          {props.data.current_approval_level === props.data.approval_level &&
            props.data.current_approval_count ===
              props.data.current_approval_required_count &&
            props.data.po_no && (
              <Link
                to={urls.purchase_order}
                onClick={() => {
                  changePoStatus();
                  props.onSubmit();
                }}
                className={`text-center px-3 py-1 rounded-md bg-myPrimaryColor text-white text-sm ${
                  props.data.items.reduce((acc, next) => {
                    acc +=
                      next.unit_no -
                      (next.received_unit_no - next.returned_unit_no);
                    return acc;
                  }, 0) === 0
                    ? ""
                    : "hidden"
                }`}
              >
                Close PO
              </Link>
            )}

          <button
            onClick={() => {
              props.onSubmit();
            }}
            className={`${
              false ? "bg-gray-400 cursor-not-allowed" : "bg-myPrimaryColor"
            } rounded-md px-3 py-1 text-white`}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default PoCreateBottomSheetWidget;
