import {
    mdiInformationOutline,
  } from "@mdi/js";
  import Icon from "@mdi/react";
  import { FunctionComponent, useEffect, useState } from "react";
  import {
    approval_level,
    approval_threshold,
    getPoApprovedCounts,
    getProjectApprovedCounts,
    ProjectlevelModule,
  } from "../../../../model/purchase_order_model";
  import ApprovalData from "./approval_data_sheet";
  import StepperWidget from "../../../../../../ui/new_components/common/stepper_widget";
  import {
    ModalAlignment,
    ModalType,
    useModal,
  } from "../../../../../../context_providers/modal/modal_context";
  import { useAuth } from "../../../../../../context_providers/auth/authProvider";
  import { ACTION, MODULE } from "../../../../../../utils/enums";
import { ProcurementProjectModel, project_approval_status } from "../../../model/project_model";
  
  interface ApprovalBottomSheetWidgetProps {
    data: ProcurementProjectModel;
    onSubmit: (data: { approval_status: string; level: string }) => void;
  }
  
  const ApprovalBottomSheetWidget: FunctionComponent<
    ApprovalBottomSheetWidgetProps
  > = (props) => {
    const { user, isAuthorised } = useAuth();
    const { showModal } = useModal();
    const [expanded, setExpanded] = useState(false);
    const [showActionBtn, setshowActionBtn] = useState(false);
    const [approvalCounts, setapprovalCounts] = useState<{
      [key: string]: number;
    }>({});
    const [approvalLevels, setapprovalLevels] = useState<{
      [key: string]: number;
    }>({});
  
    useEffect(() => {
      const counts = getProjectApprovedCounts({ approvals: props.data.approvals });
      setapprovalCounts(counts);
  
      const rule =
        approval_threshold[props.data.approval_level || approval_level.level_0];
  
      const t = rule.approvals_required.reduce(
        (
          pv: {
            [key: string]: number;
          },
          v: { level: string; count: number }
        ) => ({ ...pv, [v.level]: v.count }),
        {}
      );
  
      setapprovalLevels(t);
    }, [props.data]);
  
    useEffect(() => {
      setshowActionBtn(
        (props.data.current_approval_required_count ?? 0) >
          (props.data.current_approval_count ?? 0) &&
          isAuthorised({
            action: ACTION.READ,
            module: ProjectlevelModule[props.data.current_approval_level!],
          })
      );
    }, [props.data]);
    const levels = Object.keys(approvalLevels);
    return (
      <div className="">
        {props.data.approval_level !== approval_level.level_0 && (
          <div className="mt-2 border rounded-md p-2 bg-gray-50 mb-2">
            <div className="pb-5">
            {console.log("Curr",props.data.current_approval_level)}
              <StepperWidget
                steps={levels.map((level, i) => ({
                  label: level,
                  validated:
                    i < levels.indexOf(props.data.current_approval_level!) ||
                    (props.data.approval_level ===
                      props.data.current_approval_level &&
                      (props.data.current_approval_count ?? 0) >=
                        (props.data.current_approval_required_count ?? 0)),
                  required: false,
                }))}
               
                activeStep={levels.indexOf(props.data.current_approval_level!)}
                onChange={function (step: number): void {
                  showModal({
                    container: (i) => (
                      <div className="p-5">
                        <ApprovalData
                          approvals={
                            props.data.approvals?.filter(
                              (f) => f.level === levels[step]
                            ) ?? []
                          }
                          approved_count={approvalCounts[levels[step]] ?? 0}
                          approval_count_required={approvalLevels[levels[step]]}
                          approval_level={levels[step]}
                        />
                      </div>
                    ),
                    title: `${levels[step]} Approval Detail`,
                    type: ModalType.drawer,
                    alignment: ModalAlignment.right,
                  });
                }}
              />
            </div>
          </div>
        )}
        <div className="flex justify-between ">
          <div className="">
            {/* <div className="flex gap-2 items-center">
              <p className="font-semibold ">
                Approval Level :{" "}
                <span className="rounded-full px-4 py-1 bg-green-100 text-green-600 ">
                  {props.data.current_approval_level}
                </span>
              </p>
            </div> */}
            <div className="flex gap-1 text-gray-500 text-xs mt-2 items-center">
              <Icon path={mdiInformationOutline} size={0.8}></Icon>
              This Purchase order requires {props.data.approval_level} approval
            </div>
          </div>
          {/* <div className="flex gap-2 items-center">
            <p className="font-semibold ">
              Required Approval Level :{" "}
              <span className="rounded-full px-4 py-1 bg-green-100 text-green-600 ">
                {props.data.approval_level}
              </span>
            </p>
          </div> */}
          {showActionBtn && (
            <div className="flex items-end gap-5 text-sm">
              <button
                onClick={() => {
                  props.onSubmit({
                    approval_status: project_approval_status.rejected,
                    level: props.data.current_approval_level!,
                  });
                }}
                className={`${
                  false
                    ? "bg-gray-400 cursor-not-allowed"
                    : "border-myPrimaryColor"
                } rounded-md border px-3 py-1 text-myPrimaryColor`}
              >
                Reject
              </button>
              {console.log(props.data.current_approval_level,"props.data.current_approval_level")}
              <button
                onClick={() => {
                  props.onSubmit({
                    approval_status: project_approval_status.approved,
                    level: props.data.current_approval_level!,
                  });
                }}
                className={`${
                  false
                    ? "bg-gray-400 cursor-not-allowed"
                    : "bg-myPrimaryColor border border-myPrimaryColor"
                } rounded-md px-3 py-1 text-white`}
              >
                Approve
              </button>
            </div>
          )}
        </div>
        {/* <div className="flex items-center gap-1">
          <p className="font-semibold  italic text-sm text-gray-800">
            Approval details
          </p>
  
          <span
            onClick={() => setExpanded((o) => !o)}
            className="hover:bg-gray-100 rounded-full p-1 hover:scale-110 transform duration-200"
          >
            <Icon
              path={expanded ? mdiChevronUp : mdiChevronDown}
              className="h-5 w-5"
            ></Icon>
          </span>
        </div> */}
        {expanded && (
          <>
            {/* <div className="flex justify-between items-center mb-2 ">
              <div
                onClick={() => setExpanded(false)}
                className="hover:scale-110 duration-200 transform bg-gray-100 p-1 rounded-full hover:text-myPrimaryColor"
              >
                <Icon path={mdiClose} className="h-4 w-4"></Icon>
              </div>
            </div> */}
          </>
        )}
      </div>
    );
  };
  
  export default ApprovalBottomSheetWidget;
  