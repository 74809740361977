/* eslint-disable react-hooks/exhaustive-deps */
import { mdiInformationOutline } from "@mdi/js";
import Icon from "@mdi/react";
import { FunctionComponent, useEffect, useState } from "react";
import StepperWidget from "../../../../../../ui/new_components/common/stepper_widget";
import { useAuth } from "../../../../../../context_providers/auth/authProvider";
import {
  useModal,
  ModalType,
  ModalAlignment,
} from "../../../../../../context_providers/modal/modal_context";
import { ACTION } from "../../../../../../utils/enums";
import { ProcurementProjectModel } from "../../../model/project_model";
import {
  approval_level,
  approval_threshold,
  getProjectApprovedCounts,
  ProjectlevelModule,
} from "../../../../model/purchase_order_model";
import ApprovalData from "../../project_budget_approval/components/approval_data_sheet";

interface PoCreateBottomSheetWidgetProps {
  data: ProcurementProjectModel;
  onSubmit: () => void;
}

const ApprovalBottomSheetWidget: FunctionComponent<
  PoCreateBottomSheetWidgetProps
> = (props) => {
  const { user, isAuthorised } = useAuth();
  const { showModal } = useModal();
  const [showActionBtn, setshowActionBtn] = useState(false);
  const [approvalCounts, setapprovalCounts] = useState<{
    [key: string]: number;
  }>({});
  const [approvalLevels, setapprovalLevels] = useState<{
    [key: string]: number;
  }>({});

  useEffect(() => {
    const counts = getProjectApprovedCounts({
      approvals: props.data.approvals,
    });
    setapprovalCounts(counts);

    const rule =
      approval_threshold[props.data.approval_level || approval_level.level_0];

    const t = rule.approvals_required.reduce(
      (
        pv: {
          [key: string]: number;
        },
        v: { level: string; count: number }
      ) => ({ ...pv, [v.level]: v.count }),
      {}
    );

    setapprovalLevels(t);
  }, [props.data]);

  useEffect(() => {
    setshowActionBtn(
      (props.data.current_approval_required_count ?? 0) >
        (props.data.current_approval_count ?? 0) &&
        isAuthorised({
          action: ACTION.READ,
          module: ProjectlevelModule[props.data.current_approval_level!],
        })
    );
  }, [props.data]);
  const levels = Object.keys(approvalLevels);

  return (
    <div className="">
      {props.data.approval_level !== approval_level.level_0 && (
        <div className="mt-2 border rounded-md p-2 bg-gray-50 mb-2">
          <div className="pb-5">
            <StepperWidget
              steps={levels.map((level, i) => ({
                label: level,
                validated:
                  i < levels.indexOf(props.data.current_approval_level!) ||
                  (props.data.approval_level ===
                    props.data.current_approval_level &&
                    (props.data.current_approval_count ?? 0) >=
                      (props.data.current_approval_required_count ?? 0)),
                required: false,
              }))}
              activeStep={levels.indexOf(props.data.current_approval_level!)}
              onChange={function (step: number): void {
                showModal({
                  container: (i) => (
                    <div className="p-5">
                      <ApprovalData
                        approvals={
                          props.data.approvals?.filter(
                            (f) => f.level === levels[step]
                          ) ?? []
                        }
                        approved_count={approvalCounts[levels[step]] ?? 0}
                        approval_count_required={approvalLevels[levels[step]]}
                        approval_level={levels[step]}
                      />
                    </div>
                  ),
                  title: `${levels[step]} Approval Detail`,
                  type: ModalType.drawer,
                  alignment: ModalAlignment.right,
                });
              }}
            />
          </div>
        </div>
      )}
      <div className="flex justify-between ">
        <div className="">
          <div className="flex gap-1 text-gray-500 text-xs mt-2 items-center">
            <Icon path={mdiInformationOutline} size={0.8}></Icon>
            This Purchase order requires {props.data.approval_level} approval
          </div>
        </div>

        <div className="text-sm font-semibold flex gap-2">
          <button
            onClick={() => {
              props.onSubmit();
            }}
            className={`${
              false ? "bg-gray-400 cursor-not-allowed" : "bg-myPrimaryColor"
            } rounded-md px-3 py-1 text-white`}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ApprovalBottomSheetWidget;
