import { FunctionComponent, useEffect, useState } from "react";
import { useParams, useRouteMatch } from "react-router-dom";
// import DrawerWidget from "../../../../context_providers/modal/drawer_widget";
import LoadingWidget from "../../../../../context_providers/modal/loader";
import {
  useToast,
  ToastType,
} from "../../../../../context_providers/toast/toast_ctx";
import {
  fetchPut,
  fetchPost,
  fetchGet,
} from "../../../../../service/api_client";
import DoneWidget from "../../../../../ui/new_components/common/done";
import {
  ProcurementProjectModel,
  procurementProjectDefaultValue,
  project_approval_status,
} from "../../model/project_model";
import { urls } from "../../../../../utils/urls";
import ProjectSearchByIdField from "../project_search_id";
import ApprovalBottomSheetWidget from "./components/bottom_sheet";
import {
  auto_approval_amount,
  getAprrovalLevel,
} from "../../../model/purchase_order_model";

interface ProjectCreateScreenProps {
  data?: ProcurementProjectModel;
  onClose: (id: ProcurementProjectModel) => void;
}

const ProjectCreateScreen: FunctionComponent<ProjectCreateScreenProps> = (
  props
) => {
  // const { user } = useAuth();
  const { id } = useParams<{ id: string }>();
  // const master_url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/project_master`;
  const url = `${process.env.REACT_APP_BACKEND_BASE_URL}/api/v3/erp/project`;
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSucess] = useState(false);
  const { showToast } = useToast();
  // const [addNewTaxId, setAddNewTaxId] = useState<number>();
  // const [oldTotal, setOldTotal] = useState(0);
  const [data, setData] = useState<ProcurementProjectModel>({
    ...procurementProjectDefaultValue,
  });
  const [prvData, setPrvData] = useState<ProcurementProjectModel>({
    ...procurementProjectDefaultValue,
  });

  const is_update_page = useRouteMatch(
    urls.procurement_project + "/update/:id"
  );

  useEffect(() => {
    data.approval_level = getAprrovalLevel(data.amount);
    setData({ ...data });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.amount]);

  useEffect(() => {
    if (data.amount <= auto_approval_amount) {
      // no approval required
      console.log(" no approval required");

      data.approval_required = false;
      data.approval_status = project_approval_status.approved;
    } else {
      // approval required
      if (prvData.approval_status === project_approval_status.approved) {
        // already approved
        console.log("already approved");

        if (data.amount > prvData.amount) {
          //new total > old total
          console.log("new total > old total");

          data.approval_required = true;
          data.approval_status = project_approval_status.pending;
        } else {
          data.approval_required = prvData.approval_required;
          data.approval_status = prvData.approval_status;
        }
      } else {
        // already not approved
        console.log("already not approved");
        data.approval_required = prvData.approval_required;
        data.approval_status = prvData.approval_status;
      }
    }
    setData({ ...data });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prvData, data.amount]);

  const submit = async () => {
    if (!data.name || !data.amount) {
      return showToast({
        type: ToastType.error,
        text: "Please fill all the required details",
      });
    }
    setLoading(true);
    const res = data.id
      ? await fetchPut(url + "/" + data.id, data)
      : await fetchPost(url, data);
    if (res.success) {
      if (!data.id) setShowSucess(true);
      setData(res.data);
      setPrvData(res.data);
      showToast({ type: ToastType.success, text: res.message });
      if(id) getData(id);
      if (props.onClose) props.onClose(res.data);
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };
  useEffect(() => {
    if (id && is_update_page) getData(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = async (id: string) => {
    setLoading(true);
    const res = await fetchGet(url + "/" + id);
    if (res.success) {
      setData({ ...res.data });
      setPrvData({ ...res.data });
    } else showToast({ type: ToastType.error, text: res.error });
    setLoading(false);
  };

  useEffect(() => {
    data.approval_level = getAprrovalLevel(data.amount);
    setData({ ...data });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.amount]);

  // const onSelectAttchment = (d: AttachmentModel) => {
  //   window.open(d.url, "_blank");
  // };
  if (showSuccess)
    return (
      <div className="w-full h-full flex flex-col items-center justify-center">
        <div className="w-80 h-80 p-0">
          <DoneWidget />
        </div>
        <div className="flex flex-col gap-2 justify-start items-center">
          <h1 className="text-3xl font-semibold text-gray-400 ">
            Created successfully
          </h1>
          <span className="text-black italic font-semibold"># {data.id}</span>
          <div className="flex gap-5">
            <button
              onClick={() => setShowSucess(false)}
              className="border rounded-3xl px-5 py-2 text-green-800 border-green-400"
            >
              Continue
            </button>
            <button
              onClick={() => {
                setData({
                  ...procurementProjectDefaultValue,
                });
                setPrvData({
                  ...procurementProjectDefaultValue,
                });
                setShowSucess(false);
              }}
              className="border rounded-3xl px-4 py-2 bg-green-400 text-white  "
            >
              Create new
            </button>
          </div>
        </div>
      </div>
    );

  return (
    <>
      <form onSubmit={submit} className=" py-2 px-6">
        <div className="flex items-center justify-between mb-2">
          <div className="text-xl font-bold border-l-4 border-myPrimaryColor pl-2">
            Project <span className="text-sm">#{data.id}</span>
          </div>
        </div>

        <div className="bg-white rounded-lg px-3 py-5 ">
          <div className="grid grid-cols-1 md:grid-cols-2 text-sm">
            <div className="flex flex-col gap-5 w-full">
              <div className="grid grid-cols-4">
                <label htmlFor="name">Name</label>

                <ProjectSearchByIdField
                  placeholder="Select Project"
                  value={data}
                  onSelect={(project) => {
                    setData((prevData) => ({
                      ...prevData,
                      name: project.name + "-" + project.id,
                      br_id: project.id,
                    }));
                  }}
                  disabled={false}
                />

                {/* <ProjectSearchByIdField
                  disabled={false} // Always editable
                  placeholder="Select Project"
                  value={data.name}
                  onSelect={(d) => {
                    setData((prevData) => ({
                      ...prevData,
                      name: d.name + "-" + d.id,
                      // amount: d.amount, // Set the amount in the main data state
                      br_id: d.id,
                    }));
                  }}
                /> */}
              </div>
              <div className="grid grid-cols-4">
                <label htmlFor="status">Status</label>
                <input
                  value={data.approval_status}
                  onChange={(e) => {
                    setData((o) => ({
                      ...o,
                      status: e.target.value || "",
                    }));
                  }}
                  disabled={true}
                  type="string"
                  id="status"
                  required
                  placeholder=""
                  className=" rounded bg-gray-100 p-1 col-span-3 focus:outline-none text-right"
                />
              </div>
              <div className="grid grid-cols-4">
                <label htmlFor="amount">Amount</label>
                <input
                  value={data.amount}
                  onChange={(e) => {
                    setData((o) => ({
                      ...o,
                      amount: Number(e.target.value || 0),
                    }));
                  }}
                  type="number"
                  id="amount"
                  required
                  placeholder=""
                  className=" rounded bg-gray-100 p-1 col-span-3 focus:outline-none text-right"
                />
              </div>
            </div>
          </div>
        </div>

        {/* <div className="flex justify-center gap-5 text-sm my-5">
          <button className="rounded-lg p-2 px-5 bg-teal text-white  hover:bg-green-800 border border-teal hover:border-gree">
            Submit
          </button>
          <button
            onClick={() => window.history.back()}
            type="button"
            className="rounded-lg p-2 px-5 border border-teal hover:bg-teal hover:text-white"
          >
            Cancel
          </button>
        </div> */}
      </form>
      {loading && <LoadingWidget />}
      <div className="sticky bottom-0 right-0 mt-3 w-full rounded-t-md bg-white shadow-sm py-3 px-5 border">
        <h3 className="text-sm font-semibold">Approval status</h3>
        <ApprovalBottomSheetWidget data={data} onSubmit={submit} />
      </div>
    </>
  );
};

export default ProjectCreateScreen;
